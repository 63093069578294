<template>
  <div>
    <h3>Image uploading...</h3>
    <p>{{ message }}</p>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "upload",
  data() {
    return {
      message: "Hello World",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Upload" }]);
    ApiService.get("/diseases")
      .then(({ data }) => {
        this.message = data;
      })
      .catch(({ error }) => {
        alert("Upload.vue:" + error);
      });
  },
};
</script>
